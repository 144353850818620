/* eslint-disable no-underscore-dangle */
import dynamicModules from '../../tissues/dynamicModules';

const getModuleTemplates = (modules) => modules?.map((module, index) => {
  // Strapi store's the module's title under __component: modules.[moduleTitle]
  // and changes them to kebab case.
  // normalize included data to fit global usage/existing event data.
  const title = module.__component.split?.('modules.')?.[1]?.replace(/-/g, '_');
  return ({
    Template: dynamicModules[title],
    position: index,
    title,
    data: module
  });
});

export default getModuleTemplates;
